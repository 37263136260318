import { useState } from 'react'
import { MotionProps } from 'framer-motion'
import { chakra, BoxProps } from '@chakra-ui/react'
import NextImage, { ImageProps as NextImageProps, ImageLoaderProps as NextImageLoaderProps } from 'next/image'

import { MotionBox } from 'components/Motion'

// Note: Adding MotionProps to the props types gave overlap issues.
// Add more properties if needed.
type CustomMotionProps = {
  variants?: MotionProps['variants']
  initial?: MotionProps['initial']
  animate?: MotionProps['animate']
  exit?: MotionProps['exit']
}

type ImageProps = { className?: string } & NextImageProps & CustomMotionProps & BoxProps

export interface ImageInterface {
  src: string
  alt: string
}

const loader = (resolverProps: NextImageLoaderProps): string => {
  return `${resolverProps.src}?w=${resolverProps.width}&q=${resolverProps.quality || 100}`
}

const ChakraImage = chakra(NextImage, {
  shouldForwardProp: (prop) =>
    [
      'width',
      'height',
      'src',
      'alt',
      'quality',
      'placeholder',
      'blurDataURL',
      'loader',
      'objectFit',
      'objectPosition',
      'layout',
      'priority',
      'onLoadingComplete',
    ].includes(prop),
})

const Image = (props: ImageProps) => {
  const [isLoaded, setIsLoaded] = useState(false)

  function handleLoadComplete() {
    setIsLoaded(true)
  }

  const {
    src,
    alt,
    width,
    quality = 75,
    height,
    objectFit,
    objectPosition,
    priority,
    layout = 'responsive',
    ...rest
  } = props

  return (
    <MotionBox pos="relative" {...rest}>
      <ChakraImage
        opacity={isLoaded ? 1 : 0}
        css={'transition: opacity 0.3s linear;'}
        width={width}
        height={height}
        quality={quality}
        loader={loader}
        objectFit={objectFit}
        objectPosition={objectPosition}
        priority={priority}
        layout={layout}
        src={src}
        alt={alt}
        onLoadingComplete={handleLoadComplete}
      />
    </MotionBox>
  )
}

export default Image
