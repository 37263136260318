import { useState, useEffect, useRef, useCallback } from 'react'
import { throttle } from 'lodash'
const isClient = () => typeof window !== 'undefined'

function useVisualViewport(time = 200) {
  const visualViewport = useRef<VisualViewport>()
  const documentRef = useRef<Document>()

  useEffect(() => {
    visualViewport.current = window.visualViewport
    documentRef.current = document
  }, [])

  const getSize = useCallback(() => {
    return visualViewport.current
      ? visualViewport.current
      : {
          width: documentRef.current ? Math.max(documentRef.current.documentElement.clientWidth, window.innerWidth) : 0,
          height: documentRef.current
            ? Math.max(documentRef.current.documentElement.clientHeight, window.innerHeight)
            : 0,
        }
  }, [documentRef])

  const [windowSize, setWindowSize] = useState(getSize())

  const resize = useCallback(() => {
    setWindowSize(getSize)
  }, [setWindowSize, getSize])

  useEffect(() => {
    if (!isClient()) return
    const handleResize = throttle(resize, time)

    if (visualViewport.current) {
      window.visualViewport.addEventListener('resize', handleResize, false)
      window.visualViewport.addEventListener('scroll', handleResize, false)
    } else {
      window.addEventListener('resize', handleResize, false)
    }

    handleResize()

    return () => {
      if (visualViewport.current) {
        window.visualViewport.removeEventListener('resize', handleResize)
        window.visualViewport.removeEventListener('scroll', handleResize, false)
      } else {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [time, resize])

  return windowSize
}

export default useVisualViewport
