import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'
import { SimplexNoise } from 'three/examples/jsm/math/SimplexNoise'
import { Group, MathUtils, MeshStandardMaterial, Object3D, SkinnedMesh, Vector2, Vector3 } from 'three'
import { getAsset, Settings } from 'hooks/useAssetLoader'
import { debugFolder, debugInput } from '../utils/debug'
import { clamp, map, mix } from '../../utils/math'
import GLTFPlayer from '../controllers/GLTFPlayer'
import useMousePosition from 'hooks/useMousePosition'
import { app, Animations, dispatcher, Events, tweener } from '../global'
import { IS_DEV } from '../../constants'

const config = {
  position: { x: 0, y: -1, z: 0 },
  rotation: { x: 0, y: 0, z: 0 },
  scale: 10,
  playing: true,
  currentAnimation: '',
  currentAction: undefined,
  loop: true,
  timeScale: 0.2,
  time: 0,
  actionTime: 0,
  duration: 0,
  envMapIntensity: 30,
  followMouse: true,
  shake: true,
  shakeX: 0.1,
  shakeXFrequency: 0.1,
  shakeY: 0.1,
  shakeYFrequency: 0.1,
  shakeZ: 0.1,
  shakeZFrequency: 0.1,
  drag: {
    down: false,
    pos: new Vector2(),
    rot: new Vector3(),
    target: new Vector2(),
    maxDrag: 170,
    maxRotation: 90,
    speed: 8,
  },
}
const cursor = {
  over: false,
  object: undefined,
  offset: new Vector2(),
}

const dummy = new Object3D()
let gltfPlayer = undefined
// Flags
let isOver = false
let isDown = false

export default function Astronaut() {
  const containerRef = useRef<Group>()
  const ambientRef = useRef<Group>()
  const group = useRef()
  const { gl } = useThree()
  const gltf = getAsset('astronaut', 'homePage').data as GLTF
  const model = gltf.scene
  const transition = 0.67
  let visorMat: MeshStandardMaterial

  // Shake
  const [xNoise] = useState(() => new SimplexNoise())
  const [yNoise] = useState(() => new SimplexNoise())
  const [zNoise] = useState(() => new SimplexNoise())

  const checkFollow = () => {
    if (app.footerVisible) config.followMouse = true
  }

  const playIdle = () => {
    if (app.footerVisible) config.followMouse = true
    checkFollow()
    gltfPlayer.transitionTo('01_idleFloat', transition)
  }

  const playFalling = () => {
    if (app.footerVisible) config.followMouse = true
    gltfPlayer.transitionTo('17_falling', transition)
  }

  const playFlip = () => {
    if (app.footerVisible) config.followMouse = true
    gltfPlayer.transitionTo('06_frontFlip', transition, playIdle)
  }

  const playGuitar = () => {
    config.followMouse = false
    config.drag.target.set(0, 0)
    gltfPlayer.transitionTo('16_guitarPlaying', 1)
  }

  const playKick = () => {
    if (app.footerVisible) config.followMouse = true
    const animation = '18_hurricaneKick'
    const duration = gltfPlayer.getDuration(animation)
    gltfPlayer.transitionTo(animation, 0.5)
    const ani = { progress: 0 }
    tweener.to(ani, duration - transition, {
      progress: 1,
      onUpdate: (value: number) => {
        const parabola = Math.pow(4.0 * value * (1.0 - value), 1.5)
        const timeScale = mix(0.2, 1.67, parabola)
        gltfPlayer.setTimeScale('18_hurricaneKick', timeScale)
      },
      onComplete: playIdle,
    })
  }

  const playMindRead = () => {
    if (app.footerVisible) config.followMouse = true
    checkFollow()
    gltfPlayer.transitionTo('14_mindReadIdle', transition)
  }

  const playSwim = () => {
    if (app.footerVisible) config.followMouse = true
    gltfPlayer.transitionTo('12_breastStrokeFloat_long', transition)
  }

  const playWaving = () => {
    if (app.footerVisible) config.followMouse = true
    gltfPlayer.transitionTo('15_doubleHandWaving', 1, playIdle)
  }

  const onAnimation = (evt: any) => {
    switch (evt.value) {
      case Animations.None:
        gltfPlayer.stopAll()
        break
      case Animations.Idle:
        playIdle()
        break
      case Animations.Intro:
        app.footerVisible ? playIdle() : playSwim()
        break
      case Animations.Falling:
        playFalling()
        break
      case Animations.Flip:
        playFlip()
        break
      case Animations.Guitar:
        playGuitar()
        break
      case Animations.Mindset:
        playMindRead()
        break
      case Animations.Swim:
        playSwim()
        break
      case Animations.Waving:
        playWaving()
        break
      case Animations.Wildlife:
        playKick()
        break
    }
  }

  const initDebug = () => {
    const container = containerRef.current as Group
    const characterFolder = debugFolder('Character')
    const debug = {
      scale: container.scale.x,
    }
    debugInput(characterFolder, container, 'visible')
    debugInput(characterFolder, container, 'position')
    debugInput(characterFolder, debug, 'scale', {
      onChange: (value: number) => {
        container.scale.setScalar(value)
      },
    })
    debugInput(characterFolder, config, 'followMouse')

    // Drag
    const dragFolder = debugFolder('Drag', false, characterFolder)
    debugInput(dragFolder, config.drag, 'maxDrag', {
      min: 0,
      max: 1000,
    })
    debugInput(dragFolder, config.drag, 'maxRotation', {
      min: 0,
      max: 180,
    })
    debugInput(dragFolder, config.drag, 'speed', {
      min: 0,
      max: 20,
    })

    // Transform
    const shakeFolder = debugFolder('Ambient Offset', false, characterFolder)
    debugInput(shakeFolder, config, 'shake')
    debugInput(shakeFolder, config, 'shakeX', {
      min: 0,
      max: 3,
    })
    debugInput(shakeFolder, config, 'shakeXFrequency', {
      min: 0,
      max: 3,
    })
    debugInput(shakeFolder, config, 'shakeY', {
      min: 0,
      max: 3,
    })
    debugInput(shakeFolder, config, 'shakeYFrequency', {
      min: 0,
      max: 3,
    })
    debugInput(shakeFolder, config, 'shakeZ', {
      min: 0,
      max: 3,
    })
    debugInput(shakeFolder, config, 'shakeZFrequency', {
      min: 0,
      max: 3,
    })
  }

  const resetTransform = () => {
    const container = containerRef.current as Group
    container.position.set(0, 0, 0)
    container.rotation.set(0, 0, 0)
    config.drag.target.set(0, 0)
  }

  const enterHeader = () => {
    resetTransform()
    playSwim()
    const container = containerRef.current as Group
    container.scale.setScalar(1.75)
  }

  const enterFooter = () => {
    resetTransform()
    playIdle()
    const container = containerRef.current as Group
    container.scale.setScalar(1.5)
  }

  const onDragStart = useCallback((evt: any) => {
    if (!app.footerVisible) return
    if (evt.intersections[0].object.name !== '') return
    const container = containerRef.current as Group
    app.dragging = true
    config.drag.pos.set(evt.clientX, evt.clientY)
    config.drag.rot.set(container.rotation.x, container.rotation.y, container.rotation.z)
    gl.domElement.style.cursor = 'grabbing'
    isDown = true
    window.addEventListener('mouseup', onDragEnd, false)
  }, [])

  const onDragEnd = useCallback(() => {
    if (!app.dragging) return
    app.dragging = false
    isDown = false
    window.removeEventListener('mouseup', onDragEnd)
    if (!isOver) {
      dispatcher.dispatchEvent({
        type: Events.CURSOR,
        value: undefined,
      })
    }
  }, [])

  const onRollOver = useCallback((evt: any) => {
    isOver = true
    dispatcher.dispatchEvent({
      type: Events.CURSOR,
      value: evt.object,
    })
  }, [])

  const onRollOut = useCallback(() => {
    isOver = false
    if (!isDown) {
      dispatcher.dispatchEvent({
        type: Events.CURSOR,
        value: undefined,
      })
    }
  }, [])

  const onCursor = (evt: any) => {
    const item = evt.value
    if (item !== undefined) {
      if (item.name.length > 0) {
        cursor.over = true
        cursor.object = item
        cursor.offset.set(item.scale.x / 2, item.scale.y / 2)
      }
    } else {
      cursor.over = false
    }
  }

  useEffect(() => {
    gltfPlayer = new GLTFPlayer('Astronaut', gltf)
    gltfPlayer.setLoop('06_frontFlip', 0)
    gltfPlayer.setLoop('15_doubleHandWaving', 0)
    gltfPlayer.setLoop('18_hurricaneKick', 0)
    gltfPlayer.setTimeScale('01_idleFloat', 0.5)
    gltfPlayer.setTimeScale('06_frontFlip', 0.25)
    gltfPlayer.setTimeScale('12_breastStrokeFloat_long', 0.25)
    gltfPlayer.setTimeScale('15_doubleHandWaving', 0.5)
    gltfPlayer.setTimeScale('16_guitarPlaying', 0.5)
    gltfPlayer.setTimeScale('17_falling', 0.25)

    // Default
    const character = group.current as Group

    const helmet = character.getObjectByName('modelastroCombined_5') as SkinnedMesh
    helmet.material['roughness'] = 0

    const visor = model.getObjectByName('modelastroCombined_7') as SkinnedMesh
    visorMat = visor.material as MeshStandardMaterial
    visorMat.map = null
    visorMat.normalMap = null
    visorMat.roughnessMap = null
    visorMat.roughness = 0.2
    visorMat.metalness = 1
    visorMat.needsUpdate = true

    dispatcher.addEventListener(Events.ANIMATION, onAnimation)
    dispatcher.addEventListener(Events.CURSOR, onCursor)
    dispatcher.addEventListener(Events.ENTER_HEADER, enterHeader)
    dispatcher.addEventListener(Events.ENTER_FOOTER, enterFooter)
    if (IS_DEV) initDebug()

    return () => {
      dispatcher.removeEventListener(Events.ANIMATION, onAnimation)
      dispatcher.removeEventListener(Events.CURSOR, onCursor)
      dispatcher.removeEventListener(Events.ENTER_HEADER, enterHeader)
      dispatcher.removeEventListener(Events.ENTER_FOOTER, enterFooter)
      window.removeEventListener('mouseup', onDragEnd)
    }
  }, [])

  // Animation update
  const mousePos = Settings.mobile ? null : useMousePosition({ isEnabled: true })
  useFrame((state, delta) => {
    if (!app.headerVisible && !app.footerVisible) {
      return
    }

    gltfPlayer.update(delta)
    const container = containerRef.current as Group
    const ambient = ambientRef.current as Group

    // Ambient animation
    if (config.shake) {
      const time = state.clock.elapsedTime
      const x = config.shakeX * xNoise.noise(time * config.shakeXFrequency, 1)
      const y = config.shakeY * yNoise.noise(time * config.shakeYFrequency, 1)
      const z = config.shakeZ * zNoise.noise(time * config.shakeZFrequency, 1)
      ambient.position.set(x, y, z)
    } else {
      ambient.position.set(0, 0, 0)
    }

    if (cursor.over) {
      const pos = new Vector3()
      const scale = 0.003333
      cursor.object.getWorldPosition(pos)
      pos.x += cursor.offset.x * scale
      dummy.lookAt(pos)
      config.drag.target.x = clamp(MathUtils.degToRad(-30), Math.PI, dummy.rotation.x)
      config.drag.target.y = clamp(-Math.PI, Math.PI, dummy.rotation.y)
    }

    // Rotation animation (footer)
    const targetPosition = new Vector2()
    const time = delta * config.drag.speed
    if (app.footerVisible) {
      if (!mousePos) return
      const dpr = window.devicePixelRatio
      const width = gl.domElement.width / dpr
      const height = gl.domElement.height / dpr
      const yPos = mousePos.y - window.scrollY
      const mouseX = map(0, width, -1, 1, mousePos.x)
      const mouseY = map(0, height, 1, -1, yPos) * 2
      targetPosition
        .set(mouseX * width, mouseY * height)
        .multiplyScalar(0.001)
        .multiplyScalar(0.6)

      // Clamp
      targetPosition.x = clamp(-0.5, 0.5, targetPosition.x)
      targetPosition.y = clamp(-0.4, 0.4, targetPosition.y)
      if (app.dragging) {
        const drag = new Vector2(config.drag.maxDrag * mouseX, config.drag.maxDrag * -mouseY)
        const normal = new Vector2(drag.x / config.drag.maxDrag, drag.y / config.drag.maxDrag)
        // Target
        config.drag.target.x = normal.y * MathUtils.degToRad(config.drag.maxRotation) + config.drag.rot.x
        config.drag.target.y = normal.x * MathUtils.degToRad(config.drag.maxRotation) + config.drag.rot.y
      }
    }

    const rotationSpeed = 0.1
    container.rotation.x = MathUtils.damp(container.rotation.x, config.drag.target.x, rotationSpeed, time)
    container.rotation.y = MathUtils.damp(container.rotation.y, config.drag.target.y, rotationSpeed, time)

    if (!app.dragging) {
      if (config.followMouse) {
        const ease = 0.2
        container.position.x = MathUtils.damp(container.position.x, targetPosition.x, ease, time)
        container.position.y = MathUtils.damp(container.position.y, targetPosition.y, ease, time)
      } else {
        container.position.x = 0
        container.position.y = 0
      }
    }

    // Visible?
    const wwdVisible = !(app.currentPage === 'what-we-do' && app.headerVisible)
    container.visible = wwdVisible
  })

  return (
    <group ref={containerRef}>
      <group ref={ambientRef}>
        <group position={[0, 0.275, 0]}>
          <primitive
            ref={group}
            name="astronaut"
            object={gltf.scene}
            position={[0, -1, -0.25]}
            scale={[7, 7, 7]}
            dispose={null}
          />
          <mesh
            name="astroDrag"
            position={[0, -0.1, 0]}
            onPointerOver={onRollOver}
            onPointerOut={onRollOut}
            onPointerDown={onDragStart}
          >
            <boxBufferGeometry args={[0.6, 1.6, 0.6]} />
            <meshBasicMaterial transparent={true} opacity={0} alphaTest={0.5} />
          </mesh>
        </group>
      </group>
    </group>
  )
}
