import { rem } from 'polished'
import { useEffect } from 'react'
import { chakra, Box, Heading, VisuallyHidden } from '@chakra-ui/react'
import { Document } from '@contentful/rich-text-types'

import ArtworkHeadingHeroHome from 'components/artwork/ArtworkHeadingHeroHome'
import ContainerSlanted from 'components/containers/ContainerSlanted'
import AnimatedSplitText from 'components/AnimatedSplitText'
import ButtonArrow from 'components/buttons/ButtonArrow'

import useIntersectionObserver from 'hooks/useIntersectionObserver'
import { useThreeDispatchContext } from 'context/ThreeContext'
import useElementRect from 'hooks/useElementRect'
import useMediaQuery from 'hooks/useMediaQuery'

import { renderContentfulHeading } from 'constants/contentful'
import breakpoints from 'constants/breakpoints'
import { pct } from 'utils/designHelper'

interface HeroHomeProps {
  heading: Document
  subheading: string
  href: string
  className?: string
}

const HeroHome = ({ className, href, heading, subheading }: HeroHomeProps) => {
  const isNarrowScreen = useMediaQuery(breakpoints.lg)
  const [containerRect, containerRef] = useElementRect()
  const entry = useIntersectionObserver(containerRef, {})
  const dispatcher = useThreeDispatchContext()

  // updates based on visibility
  useEffect(() => {
    dispatcher.changeIsHeaderVisible(!!entry?.isIntersecting)

    return () => {
      dispatcher.changeIsHeaderVisible(false)
    }
  }, [entry])

  // set header rect for Three scene
  useEffect(() => {
    if (containerRect?.height) {
      dispatcher.changeHeaderRect(containerRect)
    }
  }, [containerRect])

  return (
    <ContainerSlanted
      tag="header"
      className={className}
      pos="relative"
      display="flex"
      flexDirection="column"
      w="100%"
      h={{ base: `calc(100vh - ${rem(30)})`, xl: `calc(100vh - ${rem(80)})` }}
      maxH={{ base: rem(590), xl: 'unset' }}
      minH={{ xl: rem(590) }}
      pt={{ xl: rem(110) }}
      slant={{
        base: { br: 30 },
        xl: { br: 80 },
      }}
      ref={containerRef}
    >
      <Box
        pos="relative"
        w={{ base: pct(340, 375), xl: pct(1234, 1440) }}
        maxW={rem(1234)}
        mt="auto"
        mx="auto"
        mb={{ xl: 'auto' }}
        pt={rem(32)}
        pb={rem(25)}
        textAlign={{ base: 'right', xl: 'center' }}
      >
        {isNarrowScreen ? (
          <AnimatedSplitText
            tag="h1"
            display="block"
            pl={{
              base: rem(20),
              // xl: 0
            }}
            textAlign={{
              base: 'right',
              // xl: 'left'
            }}
            fontFamily="heading"
            fontSize={{
              base: rem(56),
              // xl: fluidType(`${breakpoints.xl}px`, `${ARTBOARD_WIDTH_XL}px`, 90, 128),
              // '3xl': rem(128),
            }}
            fontWeight={200}
            letterSpacing={{ base: '-0.0675em' }}
            lineHeight={{
              base: rem(52),
              // xl: `${121 / 128}em`,
              // '3xl': rem(121),
            }}
            css={`
              em,
              code {
                font-family: inherit;
                font-style: normal;
              }

              @media (min-width: ${rem(breakpoints.xl)}) {
                text-align-last: right;
              }
            `}
          >
            {renderContentfulHeading(heading)}
          </AnimatedSplitText>
        ) : (
          <Heading as="h1">
            <VisuallyHidden>{renderContentfulHeading(heading)}</VisuallyHidden>
            <ArtworkHeadingHeroHome w="100%" h="auto" isEnabled={entry?.isIntersecting} />
          </Heading>
        )}

        <ButtonArrow mt={{ base: rem(12), xl: rem(32) }} mr={{ base: rem(-10), xl: 0 }} href={href}>
          <Box
            as="span"
            mr={{ xl: rem(8) }}
            fontSize={{ xl: rem(18) }}
            letterSpacing={{ base: '0.015em', xl: 0 }}
            lineHeight={{ base: rem(20), xl: rem(32) }}
          >
            {subheading}
          </Box>
        </ButtonArrow>
      </Box>
    </ContainerSlanted>
  )
}

export default chakra(HeroHome)
