import { rem } from 'polished'
import dynamic from 'next/dynamic'
import { useEffect, useRef } from 'react'
import { chakra, Box } from '@chakra-ui/react'
import { AnimatePresence } from 'framer-motion'

import ContainerGrid from 'components/containers/ContainerGrid'
import { MotionList, MotionListItem } from 'components/Motion'
import { GlowStickType } from 'components/GlowStick'
import Newsletter from 'components/Newsletter'
import Button from 'components/buttons/Button'
import WildlifeApp from '../three/WildlifeApp'
import Image from 'components/images/Image'
import Socials from 'components/Socials'

import { useThreeContext, useThreeDispatchContext } from 'context/ThreeContext'
import useIntersectionObserver from 'hooks/useIntersectionObserver'
import useElementRect from 'hooks/useElementRect'
import useTouchDevice from 'hooks/useTouchDevice'

import { grid, GRID_GAP_XL, offset, pct } from 'utils/designHelper'
import { ARTBOARD_WIDTH_XL } from 'constants/design'
import colors from 'constants/colors'
import {
  ContentfulContactGraphMethod,
  ContentfulGlobalSettings,
  ContentfulGraphRoute,
  ContentfulGraphSocialLink,
} from '../contentful/types'

interface FooterProps {
  newsletter: ContentfulGlobalSettings['newsletter']
  links: ContentfulGraphRoute[]
  contact: ContentfulContactGraphMethod[]
  socials: ContentfulGraphSocialLink[]
  copyright: string
  className?: string
}

const GlowStick = dynamic<GlowStickType>(() => import('components/GlowStick'))

export const FOOTER_SLANT_SM = 25
export const FOOTER_SLANT_XL = 80

const Footer = ({ className, newsletter, links, contact, socials, copyright }: FooterProps) => {
  // const refContainer = useRef()
  const refListLinks = useRef()
  const refListContact = useRef()

  const context = useThreeContext()
  const dispatcher = useThreeDispatchContext()

  const [containerRect, refContainer] = useElementRect()

  const isTouchDevice = useTouchDevice()
  const entryContainer = useIntersectionObserver(refContainer, {})
  const entryListLinks = useIntersectionObserver(refListLinks, {})
  const entryListContact = useIntersectionObserver(refListContact, {})

  useEffect(() => {
    if (containerRect?.height) {
      dispatcher.changeFooterRect(containerRect)
    }
  }, [containerRect])

  useEffect(() => {
    dispatcher.changeIsFooterVisible(entryContainer?.isIntersecting)
  }, [entryContainer])

  const { placeholder, submitLabel, successMessage, errorMessage, heading } = newsletter

  return (
    <footer className={className} ref={refContainer}>
      <Box
        pos="relative"
        display={context?.isSceneDisabled && 'none'}
        h={{ base: '75vh', xl: rem(870) }}
        mt={{ base: rem(-FOOTER_SLANT_SM), xl: rem(-FOOTER_SLANT_XL) }}
        pointerEvents={isTouchDevice ? 'none' : 'auto'}
        overflow="hidden"
      >
        <AnimatePresence>
          {context?.loadState === 'LOAD_COMPLETE' ? (
            <WildlifeApp />
          ) : (
            <Image
              pos="fixed"
              inset={0}
              src="/images/hero-home-desktop.jpg"
              alt=""
              objectFit="cover"
              layout="fill"
              animate={{ opacity: [0, 1] }}
              exit={{ opacity: 0 }}
              key="placeholder"
            />
          )}
        </AnimatePresence>
      </Box>

      <Newsletter
        pos="relative"
        w={{ xl: pct(1277, ARTBOARD_WIDTH_XL) }}
        mx={{ xl: 'auto' }}
        text={heading}
        placeholder={placeholder}
        submitLabel={submitLabel}
        errorMessage={errorMessage}
        successMessage={successMessage}
      />

      <Box pos="relative" pt={{ base: rem(48), xl: rem(67) }} pb={{ base: rem(48), xl: rem(67) }} bgColor="blue_4">
        <ContainerGrid pos="relative" display={{ xl: 'flex' }} flexWrap={{ xl: 'wrap' }}>
          <GlowStick
            pos="absolute"
            top={{ base: rem(194), xl: rem(356) }}
            right={{ base: rem(-50), xl: rem(-46) }}
            w={{ base: rem(230), xl: rem(480) }}
            glow={{ color: colors.red_1 }}
            rotation={{ base: -36 }}
          />

          <GlowStick
            pos="absolute"
            right={{ base: rem(-50), xl: 'unset' }}
            bottom={{ base: rem(192), xl: rem(112) }}
            left={{ xl: rem(50) }}
            w={{ base: rem(164), xl: rem(252) }}
            glow={{ color: colors.blue_5 }}
            rotation={{ base: 36 }}
          />

          {!!links?.length && (
            <MotionList
              w={{ xl: grid('xl', 4, 12) }}
              variants={variantsList}
              animate={entryListLinks?.isIntersecting ? 'animate' : 'initial'}
              ref={refListLinks}
            >
              {links?.length &&
                links.map((link) => (
                  <MotionListItem mt={{ base: rem(3), xl: rem(1) }} variants={variantsListItem} key={link?.sys?.id}>
                    <Button
                      fontSize={{ base: rem(24), xl: rem(36) }}
                      letterSpacing={{ base: '0.0025em', xl: rem(-1.25) }}
                      lineHeight={{ base: rem(40), xl: rem(65) }}
                      variants={variantsButton}
                      whileHover="hover"
                      href={link?.route}
                    >
                      {link?.text}
                    </Button>
                  </MotionListItem>
                ))}
            </MotionList>
          )}

          {!!contact?.length && (
            <MotionList
              w={{ xl: grid('xl', 4, 12) }}
              mt={{ base: rem(48), xl: rem(14) }}
              ml={{ xl: rem(GRID_GAP_XL) }}
              variants={variantsList}
              animate={entryListContact?.isIntersecting ? 'animate' : 'initial'}
              ref={refListContact}
            >
              {contact?.length &&
                contact.map((item, index) => {
                  const { sys: { id } = {}, label, uri, details } = item
                  return (
                    <MotionListItem mt={index > 0 && rem(44)} variants={variantsListItem} key={id}>
                      <Box
                        fontFamily="mono"
                        fontSize={{ base: rem(10) }}
                        letterSpacing={{ base: '0.2em' }}
                        lineHeight={{ base: rem(10) }}
                        textTransform="uppercase"
                      >
                        {label}
                      </Box>

                      <Button
                        mt={{ base: rem(10), xl: rem(18) }}
                        fontSize={{ base: rem(18), xl: rem(24) }}
                        lineHeight={{ base: rem(32), xl: rem(40) }}
                        _after={{
                          content: '""',
                          display: 'block',
                          w: rem(14),
                          h: rem(1),
                          mt: rem(7),
                          bgColor: 'blue_1',
                        }}
                        variants={variantsButton}
                        whileHover="hover"
                        href={uri}
                      >
                        {details}
                      </Button>
                    </MotionListItem>
                  )
                })}
            </MotionList>
          )}

          <Box w={{ xl: grid('xl', 3, 12) }} mt={{ base: rem(42), xl: rem(14) }} ml={{ xl: offset('xl', 1, 12, true) }}>
            <Box
              fontFamily="mono"
              fontSize={{ base: rem(10) }}
              letterSpacing={{ base: '0.2em' }}
              lineHeight={{ base: rem(10) }}
              textTransform="uppercase"
            >
              follow
            </Box>
            <Socials mt={{ base: rem(12) }} items={socials} />
          </Box>

          <Box
            as="small"
            display="block"
            mt={{ base: rem(55), xl: rem(185) }}
            fontFamily="mono"
            fontSize={{ base: rem(8), xl: rem(10) }}
            letterSpacing={{ base: '0.2em' }}
            lineHeight={{ base: rem(8), xl: rem(10) }}
            textTransform="uppercase"
            opacity={0.5}
          >
            {copyright}
          </Box>
        </ContainerGrid>
      </Box>
    </footer>
  )
}

const variantsList = {
  animate: {
    transition: {
      staggerChildren: 0.1,
    },
  },
}

const variantsListItem = {
  initial: {
    opacity: 0,
    x: -50,
    transition: {
      duration: 0,
    },
  },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.3,
      x: {
        ease: [0.25, 0, 0, 1],
        duration: 1,
      },
    },
  },
}

const variantsButton = {
  hover: {
    color: colors.blue_1,
    transition: {
      ease: 'linear',
      duration: 0.2,
    },
  },
}

export default chakra(Footer)
