import 'focus-visible/dist/focus-visible'
import GlobalStyle from '../styles/global'
import Fonts from '../styles/fonts'
import smoothscroll from 'smoothscroll-polyfill'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import theme from '../theme'
import { useEffect, useState } from 'react'
import { AppProps } from 'next/app'
import { MediaContextProvider } from '../styles/ssrMedia'
import { ChakraProvider } from '@chakra-ui/react'
import { Provider } from 'react-redux'
import { store } from '../store'

import ContainerTransition from 'components/containers/ContainerTransition'
import Navigation from 'components/navigation/Navigation'
import NoiseOverlay from 'components/NoiseOverlay'
import ThreeLoader from 'components/ThreeLoader'
import Footer from 'components/Footer'

import ThreeContextProvider from 'context/ThreeContext'
import AppContextProvider from 'context/AppContext'

const MediaQueryDebug = dynamic(() => import('../components/common/MediaQueryDebug'), { ssr: false })

// const IS_DEV = process.env.NODE_ENV === 'development'
const PDP_PATH = '/work/[slug]'

const App = ({ Component, pageProps, router }: AppProps) => {
  // const [isPreloaderDone, setIsPreloaderDone] = useState(router.pathname === PDP_PATH || IS_DEV)
  const [shouldThreeLoad, setShouldThreeLoad] = useState(false)

  // load three scene on mount
  useEffect(() => {
    if (router.pathname !== PDP_PATH) {
      setShouldThreeLoad(true)
    }
  }, [pageProps, router]) // pageProps to trigger useEffect on page changes

  useEffect(() => {
    smoothscroll.polyfill()
  }, [])

  // function handleAnimationCompletePreloader() {
  //   setIsPreloaderDone(true)
  // }

  // TODO: 404 page needs pageProps data
  if (!pageProps?.settings) {
    return <Component />
  }

  const {
    socialLinksCollection: { items: socialLinks },
    legal,
    routesCollection: { items: routes },
    contactInfoCollection: { items: contactInfo },
    newsletter,
  } = pageProps?.settings || {}

  return (
    <>
      <Head>
        {/* <meta name="application-name" content="Wildlife" /> */}
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <link rel="icon" href="/favicon.svg" />

        <link
          rel="preload"
          href="/fonts/ABCMarfaMono-Medium.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/ESKlarheitKurrent-Light.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/ESKlarheitGrotesk-Book.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
      </Head>

      <Provider store={store}>
        <AppContextProvider>
          <ChakraProvider theme={theme}>
            <Fonts />
            <GlobalStyle />
            <MediaContextProvider>
              <ThreeContextProvider>
                {/* <Navigation links={routes} socials={socialLinks} isEnabled={isPreloaderDone} /> */}
                <Navigation links={routes} socials={socialLinks} isEnabled={true} />

                <ThreeLoader isEnabled={shouldThreeLoad}>
                  <ContainerTransition>
                    <Component {...pageProps} canonical={router.route} key={router.route} />
                  </ContainerTransition>
                </ThreeLoader>

                <Footer
                  newsletter={newsletter}
                  links={routes}
                  contact={contactInfo}
                  socials={socialLinks}
                  copyright={legal.replace('{year}', new Date().getFullYear().toString())}
                />

                {/* <AnimatePresence>
                  {!isPreloaderDone && <Preloader onAnimationComplete={handleAnimationCompletePreloader} />}
                </AnimatePresence> */}

                <NoiseOverlay />

                <MediaQueryDebug />
              </ThreeContextProvider>
            </MediaContextProvider>
          </ChakraProvider>
        </AppContextProvider>
      </Provider>
    </>
  )
}

export default App
